import logo from './logo.svg'
import './App.css'
import React from 'react'
import { Typography, TextField, Button, FormControl, Box } from '@mui/material'

function App() {
    const [fieldValues, setFieldValues] = React.useState({
        accessToken: '',
        listId: '',
        nextPageToken: '',
        activityTypeIds: '',
    })
    const handleChange = (e) => {
        const newValues = { ...fieldValues }
        newValues[e.target.name] = e.target.value
        setFieldValues(newValues)
    }
    const handleSubmit = () => {
        console.log('😂', fieldValues)
    }
    return (
        <div className="App">
            <Typography variant="h1">Hrn Marketo API Caller</Typography>
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                }}
            >
                <form>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            width: '50em',
                        }}
                    >
                        <TextField
                            label="Access Token"
                            sx={{ margin: '10px' }}
                            onChange={handleChange}
                            name="accessToken"
                        />
                        <TextField
                            label="List ID"
                            sx={{ margin: '10px' }}
                            onChange={handleChange}
                            name="listId"
                        />
                        <TextField
                            label="Next Page Token"
                            sx={{ margin: '10px' }}
                            onChange={handleChange}
                            name="nextPageToken"
                        />
                        <TextField
                            label="Activity Type IDs"
                            sx={{ margin: '10px' }}
                            onChange={handleChange}
                            name="activityTypeIds"
                        />

                        <Button variant="contained" onClick={handleSubmit}>
                            Call Marketo API!
                        </Button>
                    </Box>
                </form>
            </Box>
        </div>
    )
}

export default App
